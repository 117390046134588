// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-podcast-js": () => import("./../../../src/pages/podcast.js" /* webpackChunkName: "component---src-pages-podcast-js" */),
  "component---src-templates-fav-js": () => import("./../../../src/templates/fav.js" /* webpackChunkName: "component---src-templates-fav-js" */),
  "component---src-templates-photo-js": () => import("./../../../src/templates/photo.js" /* webpackChunkName: "component---src-templates-photo-js" */)
}

